import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { clsx } from 'clsx';
import { input, inputError, inputVariants } from './input.css.ts';

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  errorMessage?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function TextareaComponent({ className, errorMessage, ...rest }, ref) {
    return (
      <div>
        <input
          ref={ref}
          className={clsx(
            input,
            inputVariants[errorMessage ? 'error' : 'default'],
            className,
          )}
          {...rest}
        />
        {errorMessage && <p className={inputError}>{errorMessage}</p>}
      </div>
    );
  },
);
